.testimonial-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .testimonial-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .testimonial-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .testimonial-text {
    font-style: italic;
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #555;
    max-width: 500px;
  }
  
  .testimonial-name {
    font-weight: bold;
    font-size: 1.2rem;
    color: #333;
    margin-top: 5px;
  }
  
  .testimonial-position {
    color: #777;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .testimonial-buttons {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin: 0 auto;
  }
  
  .testimonial-btn {
    background-color: #f68341 ;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .testimonial-btn:hover {
    background-color: #f68341;
  }
  