.box {
    background: var(--light-grey);
    border-radius: 20px;
  }
  
  .fw-500 {
    font-weight: 500 !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  .main-color {
    color: var(--main-color) ;
  }
  
  .secondary-color {
    color: var(--secondary-color) ;
  }
  .light-text {
    color: var(--light-text) ;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-end {
    justify-content: flex-end;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .align-items-start{
    align-items: flex-start;
  }
  
  .MuiInputBase-root {
    border-radius: 11px !important;
  }
  input {
    padding: 12px 16px !important;
  }

  .confetti-component{
    height: 200vh;
  }