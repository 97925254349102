.banner-hero{
    background-image: url(./../../../../public/assets/iphone-16-banner-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
#banner-heading{
    border-radius: 5px 5px 0px 0px;
    background-color: rgba(255, 255, 255,0.7);
    padding: 10px;
    margin: 0px;
}
#banner-para{
    border-radius: 0px 0px 5px 5px;
    padding: 5px;
    background-color: hsl(22, 91%, 61%, 0.6);
    color: white ;
}
#banner-text{
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.cta-button {
    background-color: #f68341;
    color: white;
    font-size: 25px;
    padding: 12px 33px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 15px;
    transition: 0.4s;
}
.cta-button:hover{
    background-color: white;
    color: #f68341;
    box-shadow: 0px 3px 5px grey;
}
.intro-section {
    padding: 60px 20px;
    background-color: #fafafa;
    text-align: center;
}
  
.intro-heading {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.intro-subheading {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 1.1rem;
  max-width: 650px;
  margin: 0 auto 30px;
}

.how-works-section{
    text-align: center;
}

.how-works-h{
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.how-works-h-rider{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 10px;
}
.rider-image{
    width: 100%;
    height: 350px;
    object-fit: contain;
    border-radius: 15px;
}
.rider-works-steps-ul{
    list-style: none;
    text-align: left;
    margin-top: 20px;
}

.how-works-h-driver{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 10px;
}
.driver-image{
    width: 80%;
    height: auto;
    border-radius: 15px;
}
.driver-works-steps-ul{
    list-style: none;
    text-align: left;
    margin-top: 30px;
}
.how-works-bold{
    color: #f68341;
}
.how-wirks-icons{
    margin-right: 5px;
    vertical-align: middle;
}

/* SafetyAndSecurity  styling */

.safety-security-container{
    text-align: center;
    background-color: #fafafa;
}
.safety-security-heading{
    font-size: 2.5rem;
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 5px;
    padding: 30px 0px;
}
.rider-and-driver-image{
    width: 100%;
    height: auto;
    border-radius: 15px;
}
.safetySecurity-bold{
    color: #f68341;
}
.safetySecurity-icons{
    margin-right: 5px;
    vertical-align: middle;
}

/* Join refferal programe styling */

.referral-section {
    padding: 40px 20px;
    text-align: center;
}
  
.referral-section-heading {
    font-size: 2.5rem;
    font-weight: 600;
}

.referral-section-subheading {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.referral-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}


/* Wht Rydeon styling here */

.why-choose-section {
  padding: 60px 20px;
  background-color: #fafafa;
  text-align: center;
}
/* .why-choose-part{
    
} */
.why-choose-icons{
    color: #f68341;
    font-size: 40px !important;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid grey;
}

.why-choose-heading{
    color: grey;
    font-size: 2.5rem;
    font-weight: 600;
}
.why-choose-subheading{
    font-size: 1.5rem; 
}
.timer-main{
    display: flex;
}

.timer-numbers{
    font-size: 45px;
    font-weight: 600;
    background-color: #f68341;
}
.timer-text{
    font-size: 25px;
    font-weight: 600;
}
.timer-small-box{
    background-color: #eeeeee;
    margin: 10px;
    border-radius: 8px;
}

.rotate {
    animation: rotation 2s infinite linear;
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.card-payment-main{
    max-width: 50% !important;
    width: 100%;
    border: 1px solid lightgray;
    box-shadow: 1px 2px 10px 10px rgba(128, 128, 128, 0.264);
    border-radius: 12px;
    margin: auto;
}
.card-payment-heading{
    font-size: 1.6rem;
    font-weight: 400;
}
.card-first-section{
    background-color: white;
    /* margin-left: 100px; */
    margin: 6px !important;
    padding: 20px 5px !important;
    border-radius: 8px;

}
.card-iphone-image{
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
}
.product-desc{
    display: flex;
}
.card-input{
    border: 1px solid lavender;
    margin: 0px 10px;
}
.card-submit-button{
    background-color: #f68341;
    color: white;
    font-size: 20px;
    width: 200px !important;
    padding: 5px 12px;
    margin: auto;
    text-decoration: none;
    border-radius: 15px;
    transition: 0.4s;
}
.card-submit-button:hover{
    background-color:white ;
    color: #f68341;
}
.form-heading{
    text-align: center;
    margin-bottom: 10px;
    color: #989898;
}

.login-sign-btn-div{
    position: absolute;
    right: 15px;
    top: 15px;
}
.login-sign-btn{
    color: white;
    background-color: #f68341;
    border: 1px solid #f68341;
    border-radius: 8px;
    padding: 5px 10px;
    transition: 0.4s;
}
.login-sign-btn:hover{
    color: #f68341;
    background-color: white;
}
.login-submit-btn{
    background-color: #f68341 !important;
    color: white !important;
    width: 100%;
    transition: 0.4s;
}
.login-submit-btn:hover{
    background-color: white !important;
    color: #f68341 !important;
}
.login-form-main{
    margin: auto;
    max-width: 60% !important;
    width: 100% !important;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 1px 1px 10px 2px lightgray;
}
.not-have-account{
    display: flex;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 10px;

}
.not-have-acc-txt{
    font-weight: 600;
    margin-right: 10px;
}
.to-sign-btn{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.header-main{
    background-color: #f68341;
    height: 100px;

}
.brand-name{
    color: white;
    font-size: 30px;
    cursor: pointer;
    text-align: center;
}
.links-in-header{
    display: flex;
}
.link-of-header{
    font-size: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.username-text{
    font-size: 22px;
    font-weight: 600;
    color: white;
}
.credit-card-number-div{
    display: flex;
}
.card-label-text{

}
.credit-card-number-input{
    max-width: 40%;
    margin-left: auto;
}
.credit-card-cvc-input{
    max-width: 30%;
    margin-left: auto;
}
.card-text-attr{
    max-width: 70%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    margin: auto;
}


.home-confetti-comp{
    opacity: 0.5;
}
.payment-user-info-icons{
    margin: auto !important;
    text-align: center !important;
    margin-right:5% !important;
    color: #f68341 !important;

}
.dialog-btn{
    background-color: #f68341 !important;
    color: white !important;
    transition: 0.3s !important;
}
.dialog-btn:hover{
    background-color: #e6681f !important;
}