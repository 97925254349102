@media screen and (max-width: 480px) {
    .rider-image{
        /* display: none; */
    }
    .driver-image{
        /* display: none; */
    }
    .safety-security-heading{
        font-size: 1.8rem;
    }
    .referral-section-heading{
        font-size: 1.8rem;
    }
    .why-choose-heading{
        font-size: 1.8rem;
    }
}