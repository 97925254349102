/* SmallHeader Styling */
.rydeon-header {
    background-color: #fafafa;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.small-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand-image {
  height: 40px; /* Adjust logo size */
  margin-right: 10px;
}

.brand-nav-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f68341;
}
.nav-link {
  font-size: 1rem;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 500;
}

.nav-link:hover {
  color: #f68341;
}

/* Hamburger Menu (Mobile) */
.navbar-toggler {
  background: none;
  border: none;
}

.navbar-toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #333;
  display: block;
  margin: 6px 0;
}

@media (max-width: 522px) {
    .nav-other{
        display: none;
    }
    .timer-small-box{
      margin: 2px;
      width: 50px;
      height: 100px;
    }
    .timer-numbers{
      font-size: 30px;
      font-weight: 600;
  }
  .timer-text{
      font-size: 15px;
      font-weight: 600;
  }
  .intro-section {
    padding: 30px 10px;
    background-color: #fafafa;
    text-align: center;
}
.card-payment-main{
  margin: 0px ;
}
.win-givaway-container{
  margin: 0px ;
}
.card-payment-main{
  max-width: 100% !important;
  width: 100% !important;
  margin: 10px !important;
  border: 1px solid lightgray;
  box-shadow: 1px 2px 10px 10px rgba(128, 128, 128, 0.264);
  border-radius: 12px;
  margin: auto;
}
.card-payment-main{
  margin: 50px 0px !important;
}
.why-choose-section{
  padding: 0px;
}
.brand-name{
  font-size: 22px;
}
.login-form-main{
  max-width: 100% !important;
  margin: 0px;
}
.login-form-container{
  width: 100% !important;
}
.copyright-footer{
  font-size: 20px;
  padding-bottom: 60px;
}
.giveaway-remaing-time{
  font-size: 23px;
}
.what-people-say-h{
  font-size: 23px;
}
.testimonial-container{
  background-color: rgb(250, 250, 250);
}
.jafa-dfa-dfa{
  display: none;
}
.home-confetti-comp{
  width: 100vw;
  height: 100vh;
}
.card-text-attr{
  margin: 5px;
  max-width: 100% !important;
}
.congrate-dialog-main{
  max-width: 300px !important;
  width: 100% !important;
  margin: auto !important;
}
.celebrate-icon{
  font-size: 18px !important;
}
.dialog-success-heading{
  font-size: 19px !important;
  text-align: center;
}
}

/* Footer styling */

footer{
    background-color: #f68341;
    padding: 10px;
}
.contact-anchor{
    text-decoration: none;
    display: block;
    padding: 0px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}
.contact-anchor:hover{
    text-shadow: 0px 3px 20px black;
}
.rydeon-footer-ul{
    list-style: none;
    display: flex;
    padding: 0px;
}
.footer-links-heading{
    font-size: 30px;
    font-weight: 600;
    color: white;
}

.social-links-icons{
    background-color: white;
    color: #f68341;
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
    border: 1px solid white;
    transition: 0.3s !important;
}
.social-links-icons:hover{
    background-color: #f68341;
    color: white;
}

/* .app-downlaod-row{
    
} */
.app-download-image{
    width: 128px;
    height: auto;
    margin: 0px 10px 10px 0px;
    cursor: pointer;
}


.copyright-footer{
    color: #fafafa;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600; 
    height: 30px;
    margin: auto;
}

.text-404{
  font-size: 8em;
  color: #bcbcbc;
}